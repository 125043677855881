import React, {useState, useEffect} from 'react';
import './../css/offer.css';
import { Helmet } from 'react-helmet'; // For managing document head
import { useTranslation } from 'react-i18next'; // For translations (i18n)

const Offer = () => {
  const { t } = useTranslation();
  const [scrollPosition, setScrollPosition] = useState(0);

  // Track scroll position to apply parallax effect
  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <Helmet>
        <title>Meliora Medical - {t('Offers.pageTitle')}</title>
      </Helmet>
  {/* Hero Section */}
  <div
        className="hero-container header-offers"
        style={{ backgroundPositionY: `${scrollPosition * 0}px` }} // Apply parallax effect
      >
        <div className="hero-content">
          {/* Text Section */}
          <h1 className="hero-title">{t('Offers.pageTitle')}</h1>
          {/* Contact Button */}
          <a href="tel:+355676869685" className="btn btn-primary">{t('Telefono')}</a>
        </div>
      </div>

      <h2 style={{ fontSize: '35px' }} className="services-title offers-title" id="intro">
        {t('Offers.laser4Sessions')}
      </h2>

      <section className="container price-comparison">
        <div className="price-column">
          <div className="price-header">
            <div className="price">
              <div className="dollar-sign">€</div>
              240
            </div>
            <div className="price previous-price">
              <div className="dollar-sign">€</div>
              300
            </div>
            <div className="plan-name">{t('Offers.plans.bikiniAndSqetulla')}</div>
          </div>
          <div className="divider"></div>
          <div className="feature">{t('Offers.features.4Seanca')}</div>
          <div className="feature">{t('Offers.features.4Retouch')}</div>
        </div>

        <div className="price-column normal">
          <div className="price-header">
            <div className="price">
              <div className="dollar-sign">€</div>
              145
            </div>
            <div className="price previous-price">
              <div className="dollar-sign">€</div>
              180
            </div>
            <div className="plan-name">{t('Offers.plans.bikini')}</div>
          </div>
          <div className="divider"></div>
          <div className="feature">{t('Offers.features.4Seanca')}</div>
          <div className="feature">{t('Offers.features.4Retouch')}</div>
        </div>

        <div className="price-column">
          <div className="price-header">
            <div className="price">
              <div className="dollar-sign">€</div>
              360
            </div>
            <div className="price previous-price">
              <div className="dollar-sign">€</div>
              480
            </div>
            <div className="plan-name">{t('Offers.plans.kembetTotal')}</div>
          </div>
          <div className="divider"></div>
          <div className="feature">{t('Offers.features.4Seanca')}</div>
          <div className="feature">{t('Offers.features.4Retouch')}</div>
        </div>

        <div className="price-column normal">
          <div className="price-header">
            <div className="price">
              <div className="dollar-sign">€</div>
              700
            </div>
            <div className="price previous-price">
              <div className="dollar-sign">€</div>
              1000
            </div>
            <div className="plan-name">{t('Offers.plans.trupiTotal')}</div>
          </div>
          <div className="divider"></div>
          <div className="feature">{t('Offers.features.4Seanca')}</div>
          <div className="feature">{t('Offers.features.4Retouch')}</div>
        </div>
      </section>

      <h2 style={{ fontSize: '35px' }} className="services-title offers-title" id="intro">
        {t('Offers.laser8Sessions')}
      </h2>

      <section className="container price-comparison">
        <div className="price-column">
          <div className="price-header">
            <div className="price">
              <div className="dollar-sign">€</div>
              400
            </div>
            <div className="price previous-price">
              <div className="dollar-sign">€</div>
              600
            </div>
            <div className="plan-name">{t('Offers.plans.bikiniAndSqetulla')}</div>
          </div>
          <div className="divider"></div>
          <div className="feature">{t('Offers.features.8Seanca')}</div>
          <div className="feature">{t('Offers.features.8Retouch')}</div>
        </div>

        <div className="price-column normal">
          <div className="price-header">
            <div className="price">
              <div className="dollar-sign">€</div>
              250
            </div>
            <div className="price previous-price">
              <div className="dollar-sign">€</div>
              360
            </div>
            <div className="plan-name">{t('Offers.plans.bikini')}</div>
          </div>
          <div className="divider"></div>
          <div className="feature">{t('Offers.features.8Seanca')}</div>
          <div className="feature">{t('Offers.features.8Retouch')}</div>
        </div>

        <div className="price-column">
          <div className="price-header">
            <div className="price">
              <div className="dollar-sign">€</div>
              640
            </div>
            <div className="price previous-price">
              <div className="dollar-sign">€</div>
              960
            </div>
            <div className="plan-name">{t('Offers.plans.kembetTotal')}</div>
          </div>
          <div className="divider"></div>
          <div className="feature">{t('Offers.features.8Seanca')}</div>
          <div className="feature">{t('Offers.features.8Retouch')}</div>
        </div>

        <div className="price-column normal">
          <div className="price-header">
            <div className="price">
              <div className="dollar-sign">€</div>
              990
            </div>
            <div className="price previous-price">
              <div className="dollar-sign">€</div>
              2000
            </div>
            <div className="plan-name">{t('Offers.plans.trupiTotal')}</div>
          </div>
          <div className="divider"></div>
          <div className="feature">{t('Offers.features.8Seanca')}</div>
          <div className="feature">{t('Offers.features.8Retouch')}</div>
        </div>
      </section>

      <h2 style={{ fontSize: '35px' }} className="services-title offers-title" id="intro">
        {t('Offers.anticelulit')}
      </h2>

      <section className="container price-comparison">
        <div className="price-column">
          <div className="price-header">
            <div className="price">
              <div className="dollar-sign">€</div>
              200
            </div>
            <div className="price previous-price">
              <div className="dollar-sign">€</div>
              300
            </div>
            <div className="plan-name">{t('Offers.plans.anticelulitI')}</div>
          </div>
          <div className="divider"></div>
          <div className="feature">{t('Offers.features.10SeancaMasazhKupa')}</div>
          <div className="feature">{t('Offers.features.kofshe')}</div>
          <div className="feature">{t('Offers.features.vithe')}</div>
          <div className="feature">{t('Offers.features.bark')}</div>
        </div>

        <div className="price-column normal">
          <div className="price-header">
            <div className="price">
              <div className="dollar-sign">€</div>
              250
            </div>
            <div className="price previous-price">
              <div className="dollar-sign">€</div>
              350
            </div>
            <div className="plan-name">{t('Offers.plans.anticelulitII')}</div>
          </div>
          <div className="divider"></div>
          <div className="feature">{t('Offers.features.10SeancaMasazhKupa')}</div>
          <div className="feature">{t('Offers.features.10SeancaPresoterapi')}</div>
          <div className="feature">{t('Offers.features.kofshe')}</div>
          <div className="feature">{t('Offers.features.vithe')}</div>
          <div className="feature">{t('Offers.features.bark')}</div>
        </div>

        <div className="price-column">
          <div className="price-header">
            <div className="price">
              <div className="dollar-sign">€</div>
              300
            </div>
            <div className="price previous-price">
              <div className="dollar-sign">€</div>
              400
            </div>
            <div className="plan-name">{t('Offers.plans.anticelulitIII')}</div>
          </div>
          <div className="divider"></div>
          <div className="feature">{t('Offers.features.10SeancaMasazhKupa')}</div>
          <div className="feature">{t('Offers.features.10SeancaRadiofrekuence')}</div>
          <div className="feature">{t('Offers.features.kofshe')}</div>
          <div className="feature">{t('Offers.features.vithe')}</div>
          <div className="feature">{t('Offers.features.bark')}</div>
        </div>

        <div className="price-column normal">
          <div className="price-header">
            <div className="price">
              <div className="dollar-sign">€</div>
              350
            </div>
            <div className="price previous-price">
              <div className="dollar-sign">€</div>
              450
            </div>
            <div className="plan-name">{t('Offers.plans.anticelulitIV')}</div>
          </div>
          <div className="divider"></div>
          <div className="feature">{t('Offers.features.10SeancaMasazhKupa')}</div>
          <div className="feature">{t('Offers.features.10SeancaPresoterapi')}</div>
          <div className="feature">{t('Offers.features.10SeancaRadiofrekuence')}</div>
          <div className="feature">{t('Offers.features.kofshe')}</div>
          <div className="feature">{t('Offers.features.vithe')}</div>
          <div className="feature">{t('Offers.features.bark')}</div>
        </div>
      </section>

      <h2 style={{ fontSize: '35px' }} className="services-title offers-title" id="intro">
        {t('Offers.bodyTreatments')}
      </h2>

      <section className="container price-comparison">
        <div className="price-column">
          <div className="price-header">
            <div className="price">
              <div className="dollar-sign">€</div>
              200
            </div>
            <div className="price previous-price">
              <div className="dollar-sign">€</div>
              250
            </div>
            <div className="plan-name">{t('Offers.plans.presoterapi')}</div>
          </div>
          <div className="divider"></div>
          <div className="feature">{t('Offers.features.10Seanca')}</div>
        </div>

        <div className="price-column popular">
          <div className="price-header">
            <div className="price">
              <div className="dollar-sign">€</div>
              500
            </div>
            <div className="price previous-price">
              <div className="dollar-sign">€</div>
              600
            </div>
            <div className="plan-name">{t('Offers.plans.teknikaSportive')}</div>
          </div>
          <div className="divider"></div>
          <div className="feature">{t('Offers.features.10Seanca')}</div>
          <div className="feature">{t('Offers.features.trupiTotal')}</div>
        </div>

        <div className="price-column">
          <div className="price-header">
            <div className="price">
              <div className="dollar-sign">€</div>
              350
            </div>
            <div className="price previous-price">
              <div className="dollar-sign">€</div>
              450
            </div>
            <div className="plan-name">{t('Offers.plans.detox')}</div>
          </div>
          <div className="divider"></div>
          <div className="feature">{t('Offers.features.10Seanca')}</div>
          <div className="feature">{t('Offers.features.trupiTotal')}</div>
        </div>
      </section>

      <h2 style={{ fontSize: '35px' }} className="services-title offers-title" id="intro">
        {t('Offers.faceTreatments')}
      </h2>

      <section className="container price-comparison">
        <div className="price-column">
          <div className="price-header">
            <div className="price">
              <div className="dollar-sign">€</div>
              250
            </div>
            <div className="price previous-price">
              <div className="dollar-sign">€</div>
              350
            </div>
            <div className="plan-name">{t('Offers.plans.liftingI')}</div>
          </div>
          <div className="divider"></div>
          <div className="feature">{t('Offers.features.10Seanca')}</div>
          <div className="feature">{t('Offers.features.liftingIThjeshte')}</div>
        </div>

        <div className="price-column normal">
          <div className="price-header">
            <div className="price">
              <div className="dollar-sign">€</div>
              400
            </div>
            <div className="price previous-price">
              <div className="dollar-sign">€</div>
              500
            </div>
            <div className="plan-name">{t('Offers.plans.liftingII')}</div>
          </div>
          <div className="divider"></div>
          <div className="feature">{t('Offers.features.10Seanca')}</div>
          <div className="feature">{t('Offers.features.liftingMePeptdie')}</div>
        </div>

        <div className="price-column">
          <div className="price-header">
            <div className="price">
              <div className="dollar-sign">€</div>
              275
            </div>
            <div className="price previous-price">
              <div className="dollar-sign">€</div>
              375
            </div>
            <div className="plan-name">{t('Offers.plans.hidrofacial')}</div>
          </div>
          <div className="divider"></div>
          <div className="feature">{t('Offers.features.5Seanca')}</div>
          <div className="feature">{t('Offers.features.paketeVjetore')}</div>
        </div>

        <div className="price-column normal">
          <div className="price-header">
            <div className="price">
              <div className="dollar-sign">€</div>
              150
            </div>
            <div className="price previous-price">
              <div className="dollar-sign">€</div>
              200
            </div>
            <div className="plan-name">{t('Offers.plans.peelingKimik')}</div>
          </div>
          <div className="divider"></div>
          <div className="feature">{t('Offers.features.4Seanca')}</div>
        </div>
      </section>


      <h2 style={{ fontSize: '35px' }} className="services-title offers-title" id="intro">
        {t('Offers.lymphoDrainage')}
      </h2>

      <section className="container price-comparison">
        <div className="price-column">
          <div className="price-header">
            <div className="price">
              <div className="dollar-sign">€</div>
              70
            </div>
            <div className="price previous-price">
              <div className="dollar-sign">€</div>
              80
            </div>
            <div className="plan-name">{t('Offers.features.bark')}</div>
          </div>
          <div className="divider"></div>
          <div className="feature">{t('Offers.features.1Seanc')}</div>
          <div className="feature">{t('Offers.features.bark')}</div>
        </div>

        <div className="price-column normal">
          <div className="price-header">
            <div className="price">
              <div className="dollar-sign">€</div>
              70
            </div>
            <div className="price previous-price">
              <div className="dollar-sign">€</div>
              80
            </div>
            <div className="plan-name">{t('Offers.features.kembet')}</div>
          </div>
          <div className="divider"></div>
          <div className="feature">{t('Offers.features.1Seanc')}</div>
          <div className="feature">{t('Offers.features.kembet')}</div>
        </div>

        <div className="price-column">
          <div className="price-header">
            <div className="price">
              <div className="dollar-sign">€</div>
              100
            </div>
            <div className="price previous-price">
              <div className="dollar-sign">€</div>
              120
            </div>
            <div className="plan-name">{t('Offers.features.trupiTotal')}</div>
          </div>
          <div className="divider"></div>
          <div className="feature">{t('Offers.features.1Seanc')}</div>
          <div className="feature">{t('Offers.features.trupiTotal')}</div>
        </div>
      </section>



    </div>
  );
};

export default Offer;
