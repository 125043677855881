import './App.css';
import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Analytics } from "@vercel/analytics/react"


import Navbar from './components/Navbar';
import Footer from './components/Footer';
import LoadingScreen from './components/LoadingScreen';
import LanguageSwitcher from './components/LanguageSwitcher';
import CookieConsent from './components/CookieConsent';
import PrivacyPolicy from './pages/PrivacyPolicy';

import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Offer from './pages/Offer';


const App = () => {
  const location = useLocation();

  const getBackgroundClass = (pathname) => {
    switch (pathname) {
      case '/':
        return 'homepage-background';
        case '/privacypolicy':
          return 'homepage-background';
      case '/about':
        return 'about-background';
      case '/services':
        return 'services-background';
      case '/contact':
        return 'contact-background';
        case '/offer':
          return 'services-background';
   
    }
  };

  return (
    <div className={`App ${getBackgroundClass(location.pathname)}`}>
      <Analytics />
      <LoadingScreen />
      <Navbar />
      <CookieConsent />
      <div className="content-wrapper">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/offer" element={<Offer />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
      <LanguageSwitcher />
      <Footer />
    </div>
  );
};

export default App;
